import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";
import {PageTitle} from "../../../components/page-title/page-title";
import TMSContent from "./content.json";

enum _ProblemTabEnum {
  client = "Đối với Khách hàng",
  driver = "Đối với Tài xế và đối tác vận tải",
  accountantAndWarehouseman = "Đối với kế toán và nhân viên kho",
  staff = "Đối với nhân viên điều vận và quản lý",
}

const TmsSolutionPageProblemsComponent = () => {
  const tmsProblem = TMSContent.tmsProblem;

  return (
    <section id="van-de">
      <div className="container py-5">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="Những vấn đề khó khăn trong quản lý vận tải" />
            </div>
          </div>
          <BookADemoButton className="mt-3"/>
        </div>
        <div>
          {
            tmsProblem.map((p, i) => {
              const even = i % 2 === 0;
              const cls = even ? "order-last order-md-first mt-3" : "";
              return (
                <div className="row mb-4 tms-items" key={i}>
                  <div className={`col-12 col-md-6 d-flex justify-content-center align-content-center ${cls}`}>
                    {
                      even ? (
                          <div className="d-flex flex-column justify-content-center align-content-center mx-3">
                            <h2>{p.title}</h2>
                            <ul className="list-style-disc">
                              {
                                p.content.map((c, i) => {
                                  return (
                                    <li key={i}>
                                      {c}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                      ) : <img src={p.img} alt={p.title} className="img-fluid"/>
                    }
                  </div>
                  <div className="col-12 col-md-6  d-flex justify-content-center align-content-center">
                    {
                      !even ? (
                          <div className="d-flex flex-column justify-content-center align-content-center mx-3">
                            <h2>{p.title}</h2>
                            <ul className="list-style-disc">
                              {
                                p.content.map((c, i) => {
                                  return (
                                    <li key={i}>
                                      {c}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                      ) : <img src={p.img} alt={p.title} className="img-fluid"/>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  );
};

export default TmsSolutionPageProblemsComponent;
