import { useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useRecoilValue } from "recoil";
import { useFetchBlogs } from "../../../hooks";
import { blogsState, loadBlogsState } from "../../../states";
import { MwaDateTimeUtil, SessionState } from "../../../utils";
import { PageTitle } from "../../../components/page-title/page-title";
import { Link } from "react-router-dom";
import { MwaImage } from "../../../components";

const HomePageBlogsComponent = () => {
  const fetchBlogs = useFetchBlogs();
  const blogList = useRecoilValue(blogsState);
  const loadingBlogs = useRecoilValue(loadBlogsState);

  useEffect(() => {
    switch (loadingBlogs) {
      case SessionState.idle:
      case SessionState.error:
        fetchBlogs();
        break;

      default:
        break;
    }
  }, [fetchBlogs, loadingBlogs]);

  const _renderContent = () => {
    switch (loadingBlogs) {
      case SessionState.success:
        return blogList.items.length > 0 ? (
          <div className="container d-flex flex-column justify-content-center align-items-stretch py-4">
            <div className="row">
              {blogList.items.slice(0, 6).map((blogInfo, index) => {
                return (
                  <div
                    key={index}
                    className="col-xl-2 col-md-3 col-sm-4 col-12"
                  >
                    <div className="bg-white border">
                      <Link
                        to={`/tin-tuc/${blogInfo.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-body"
                      >
                        <div className="d-flex flex-column">
                          <MwaImage
                            src={blogInfo.thumbnail}
                            alt="thumbnail-img"
                            className="img-thumbnail"
                            style={{ height: "20vh" }}
                            fallbackSrc="/img/logo.png"
                          />
                          <div className="p-2">
                            <div className="news-title">{blogInfo.name}</div>
                            {blogInfo.createdAt && (
                              <span
                                className="mt-2"
                                style={{ fontSize: ".8rem" }}
                              >
                                {MwaDateTimeUtil.historyAgo(blogInfo.createdAt)}
                              </span>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mx-auto">
              <Link
                to="/tin-tuc"
                target="_blank"
                className="btn btn-warning mt-4"
              >
                Xem tất cả tin tức
              </Link>
            </div>
          </div>
        ) : (
          <h5 className="d-flex justify-content-center py-4 m-0">
            Chưa có tin tức
          </h5>
        );

      case SessionState.loading:
        return (
          <div className="container d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-lg-6">
                <Skeleton height="50vh" />
              </div>
              <div className="col-lg-6 d-flex flex-column">
                <div className="flex-grow-1 border-top d-flex p-3">
                  <Skeleton height="6vw" width="10vw" />
                  <div className="ms-3 w-100">
                    <Skeleton count={3} height={10} />
                  </div>
                </div>
                <div className="flex-grow-1 border-top d-flex p-3">
                  <Skeleton height="6vw" width="10vw" />
                  <div className="ms-3 w-100">
                    <Skeleton count={3} height={10} />
                  </div>
                </div>
                <div className="flex-grow-1 border-top d-flex p-3">
                  <Skeleton height="6vw" width="10vw" />
                  <div className="ms-3 w-100">
                    <Skeleton count={3} height={10} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case SessionState.error:
        return (
          <div className="d-flex justify-content-center py-3">
            <button
              className="btn btn-danger d-flex flex-column justify-content-center align-items-center"
              onClick={fetchBlogs}
            >
              <span>Lỗi tải tin tức: {blogList.error?.message}</span>
              <span> Thử lại</span>
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <section id="tin-tuc" className="gray-light-bg">
      <div className="pt-5 d-flex flex-column align-items-center">
        <div className="col-md-8">
          <div className="section-heading text-center">
            <PageTitle title="Tin tức" />
          </div>
        </div>
        {_renderContent()}
      </div>
    </section>
  );
};

export default HomePageBlogsComponent;
