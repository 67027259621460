const EcommerceSolutionPageHeaderComponent = () => {
  return (
    <section
      id="ecommerce-solution-page-header"
      className="hero-section py-5 background-img"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between g-4">
          <div className="col-md-6 col-lg-6">
            <div className="hero-content-left text-white mt-5">
              <h1 className="text-white fw-bold">PyCOM E-Commerce</h1>
              <p className="lead mb-1 text-uppercase">
                Cung cấp giải pháp bán hàng toàn diện
              </p>
              <ul className="list-style-disc ps-4">
                <li>
                  Ứng dụng công nghệ mới nhất vào giúp doanh nghiệp giải quyết
                  triệt để những khó khăn trong bán hàng.
                </li>
                <li>Nâng cao khả năng chăm sóc và tiếp cận khách hàng.</li>
              </ul>
              <a
                href="#tinh-nang-tong-quan"
                className="btn btn-light page-scroll my-5"
              >
                Tìm hiểu ngay
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="hero-animation-img">
              <img
                src="img/ecommerce/introduction.png"
                alt="img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EcommerceSolutionPageHeaderComponent;
