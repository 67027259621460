import {
    DmsSolutionPageZaloMiniAppComponent,
  } from "./components";
  import "./zalo-solution.css";
  
  const ZaloSolutionPage = () => {
    return (
      <div id="dms-solution-page" className="main">
        <DmsSolutionPageZaloMiniAppComponent />
      </div>
    );
  };
  
  export default ZaloSolutionPage;