import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";

const DmsSolutionPageOpenApiSystemComponent = () => {
  return (
    <section id="dms-solution-page-open-api-system" className="gray-light-bg">
      <div className="container">
        <div className="row align-items-center py-5 d-flex flex-column justify-content-center flex-lg-row">
          <div className="row col-lg-6 order-1 order-lg-0">
            {[
              "oracle-logo.png",
              "lazada-logo.png",
              "shopee-logo.png",
              "tiki-logo.png",
              "odoo-logo.png",
              "sap-erp-logo.png",
              "bravo-erp-logo.png",
              "fast-erp-logo.png",
              "salesforce-logo.png",
            ].map((src, index) => {
              return (
                <div key={index} className="col-4">
                  <div
                    className="position-relative rounded-4 bg-white mt-3"
                    style={{
                      paddingBottom: "100%",
                      boxShadow:
                        "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <div
                      className="position-absolute p-md-3 p-0 d-flex flex-column justify-content-center"
                      style={{
                        inset: 0,
                        margin: "auto",
                      }}
                    >
                      <img
                        src={`./img/logo/${src}`}
                        alt="logo"
                        className="w-auto h-auto"
                        style={{
                          maxHeight: "7rem",
                          maxWidth: "7rem",
                          margin: "auto",
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column align-items-center mb-4">
              <div className="section-heading text-center">
                <PageTitle title="Hệ thống Open API tích hợp" />
                <p
                  className="page-sub-title mt-2"
                  style={{ textAlign: "justify" }}
                >
                  PyCOM DMS xây dựng hệ thống Open API ngay trên bản SaaS DMS.
                  Khách hàng có thể tự tiến hành kết nối với hệ thống công nghệ
                  thông tin của doanh nghiệp hay các phần mềm như ERP, Kế toán
                  đang sử dụng. Các trường dữ liệu sẽ bao gồm: khách hàng, sản
                  phẩm, viếng thăm, đơn hàng
                </p>
              </div>
              <BookADemoButton />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DmsSolutionPageOpenApiSystemComponent;
