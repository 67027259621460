import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { productsJson } from "../../contents";
import { BookADemoButton } from "../book-a-demo/book-a-demo";

const FooterComponent = () => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(true);
  const products = productsJson;

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
  }, [location]);

  return (
    <footer className="footer-section">
      <div className="footer-top py-5 background-img">
        <div className="container mt-4">
          <div className="row justify-content-between">
            <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
              <div className="footer-nav-wrap text-white">
                <h5 className="mb-2 text-white">
                  Công Ty TNHH Công nghệ PYCOM SOLUTIONS
                </h5>
                <ul className="d-flex flex-column mb-2">
                  <span className="mb-2" style={{ fontSize: 14 }}>
                    Địa chỉ: Villa D6, Village An Phú Đông, An Phú Đông, Quận
                    12, Hồ Chí Minh
                  </span>
                  <span className="mb-2" style={{ fontSize: 14 }}>
                    Chi nhánh: 126 Hà Huy Giáp, KDC An Lộc, Thạnh Lộc, Quận 12,
                    Hồ Chí Minh
                  </span>
                  <span style={{ fontSize: 14 }}>MST: 0317800291</span>
                </ul>
                <p className="text-white" style={{ fontSize: 14 }}>
                  © Powered By PyCOM Solutions.
                  <br /> All rights reserved.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Danh mục</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a href="/">Trang chủ</a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="page-scroll"
                          href={`${isHomePage ? "" : "/"}#san-pham`}
                        >
                          Sản phẩm
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="page-scroll"
                          href={`${isHomePage ? "" : "/"}#giai-phap`}
                        >
                          Giải pháp
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="page-scroll"
                          href={`${isHomePage ? "" : "/"}#gioi-thieu`}
                        >
                          Giới thiệu
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="page-scroll"
                          href={`${isHomePage ? "" : "/"}#khach-hang`}
                        >
                          Đối tác
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="page-scroll"
                          href={`${isHomePage ? "" : "/"}#tin-tuc`}
                        >
                          Tin tức
                        </a>
                      </li>
                      <li className="mb-2">
                        <a href="/chinh-sach-bao-mat">Chính sách bảo mật</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Về PyCOM</h5>
                    <ul className="list-unstyled support-list">
                      {products.map((item, index) => (
                        <li key={index} className="mb-2">
                          <a href={item.href} target={item.target}>
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <BookADemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
