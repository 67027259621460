import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";

const DmsSolutionPageProblemsComponent = () => {
  const _cardInfo = ({
    title,
    iconSrc,
    description,
  }: {
    title: string;
    iconSrc: string;
    description: string;
  }) => {
    return (
      <div className="col-lg-4 col-sm-6">
        <div className="card single-promo-card single-promo-hover h-100">
          <div className="card-body d-flex flex-column align-items-center">
            <img src={iconSrc} alt="iconSrc" width="100rem" />
            <div className="pt-2 pb-3">
              <h5 className="text-center">{title}</h5>
              <p className="text-center text-muted mb-0">{description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="van-de" className="gray-light-bg">
      <div className="container">
        <div className="row justify-content-center py-5">
          <div className="d-flex flex-column align-items-center mb-4">
            <div className="section-heading text-center">
              <PageTitle title="Các vấn đề bạn đang gặp phải" />
              </div>
              <BookADemoButton className="mt-3"/>
          </div>
          <div className="row g-4">
            {_cardInfo({
              title: "Vấn đề quản lý nhân viên Sale",
              iconSrc: "./img/dms/sc1-img-1.png",
              description:
                "Vấn đề giám sát, đánh giá hiệu suất nhân viên Sale khó khăn do thiếu dữ liệu về tiêu chí đánh giá",
            })}
            {_cardInfo({
              title: "Kiểm soát hoạt độn bán hàng",
              iconSrc: "./img/dms/sc1-img-2.png",
              description:
                "Hoạt động bán hàng không được lưu trữ bài bản dẫn đến khó kiểm soát số liệu và tình trạng của đơn hàng",
            })}
            {_cardInfo({
              title: "Kiểm soát tồn kho",
              iconSrc: "./img/dms/sc1-img-3.png",
              description:
                "Dữ liệu tồn kho trở nên thiếu chính xác dẫn đến kế hoạch kinh doanh và sản xuất dần bị sai lệch",
            })}
            {_cardInfo({
              title: "Dữ liệu báo cáo chưa hiệu quả",
              iconSrc: "./img/dms/sc1-img-4.png",
              description:
                "Dữ liệu báo cáo thiếu tính đồng nhất so với giá trị thực tế ngoài thị trường",
            })}
            {_cardInfo({
              title: "Kiểm soát dữ liệu",
              iconSrc: "./img/dms/sc1-img-5.png",
              description:
                "Việc kiểm soát tính đúng đắn của dữ liệu từ nhân viên trở nên khó khăn và phức tạp",
            })}
            {_cardInfo({
              title: "Công cụ tương tác với của hàng",
              iconSrc: "./img/dms/sc1-img-6.png",
              description:
                "Thiếu công cụ để chủ cửa hàng tương tác với nhân viên công ty",
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DmsSolutionPageProblemsComponent;
