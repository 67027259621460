import { Splide, SplideSlide } from "@splidejs/react-splide";
import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";

const DmsSolutionPageZaloMiniAppComponent = () => {
  return (
    <section id="dms-solution-page-zalo-mini-app">
      <div className="container">
        <div className="row ptb-100">
          <div className="col-lg-6">
            <div className="d-flex flex-column align-items-center mb-4">
              <div className="section-heading text-center">
                <PageTitle title="Ứng dụng đặt hàng dành cho cửa hàng" />
                <p className="page-sub-title mt-2">
                  Đặt đơn hàng cho Cửa hàng trên nền tảng Zalo Mini App
                </p>
              </div>
              <BookADemoButton />
            </div>
            {[
              "Không cần cài đặt phức tạp, chỉ cần quét mã là có thể lên đơn hàng",
              "Không cần đăng ký, đăng nhập",
              "Lên đơn hàng với chỉ vài thao tác nhanh gọn",
              "Quản lý đơn hàng và trạng thái vận chuyển",
              "Tương tác với nhân viên Sale một cách thuận tiện và linh hoạt",
            ].map((value, index) => {
              return (
                <div key={index} className="d-flex align-items-start mb-4">
                  <div className="me-3">
                    <img
                      className="icon-img"
                      src="./img/dms/tick-circle.png"
                      alt="tick-circle"
                      width="30rem"
                    />
                  </div>
                  <span className="fs-5">{value}</span>
                </div>
              );
            })}
            <div className="col-lg-9 mx-md-auto">
              <img
                src={`./img/screenshots/dms/zalo-mini-app01.png`}
                alt="banner"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-0">
            <Splide
              options={{
                type: "loop",
                autoplay: true,
                pauseOnHover: false,
                perPage: 1,
                gap: "1rem",
                breakpoints: {
                  480: {
                    perPage: 1,
                  },
                },
              }}
            >
              {[
                "dms-app-01.png",
                "dms-app-02.png",
                "dms-app-03.png",
                "dms-app-04.png",
                "dms-app-05.png",
                "dms-app-06.png",
              ].map((src, index) => {
                return (
                  <SplideSlide key={index}>
                    <img
                      src={`./img/dms/${src}`}
                      alt={`Slide ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DmsSolutionPageZaloMiniAppComponent;
