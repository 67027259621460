import { PageTitle } from "../../../components/page-title/page-title";
import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";
import { ecommerceSummaryFeaturesJson } from "../../../contents";

const EcommerceSolutionPageSummaryFeaturesComponent = () => {
  const _cardInfo = ({
    key,
    title,
    iconSrc,
  }: {
    key: number;
    title: string;
    iconSrc: string;
  }) => {
    return (
      <div key={key} className="col-lg-4 col-sm-6">
        <div className="card single-promo-card single-promo-hover h-100">
          <div className="card-body d-flex flex-column align-items-center">
            <img src={iconSrc} alt="iconSrc" width="100rem" />
            <div className="pt-4">
              <h5 className="text-center m-0">{title}</h5>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="tinh-nang-tong-quan" className="gray-light-bg">
      <div className="container">
        <div className="row justify-content-center py-5">
          <div className="d-flex flex-column align-items-center mb-4">
            <div className="section-heading text-center">
              <PageTitle title="Các tính năng tổng quan" />
            </div>
            <BookADemoButton className="mt-3" />
          </div>
          <div className="row g-4">
            {ecommerceSummaryFeaturesJson.map((item, index) =>
              _cardInfo({
                key: index,
                title: item.title,
                iconSrc: item.iconSrc,
              })
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EcommerceSolutionPageSummaryFeaturesComponent;
