import { ReactComponent as IconCustomer } from "../../../icons/icon-customer.svg";
import { ReactComponent as IconApp } from "../../../icons/icon-app.svg";
import { ReactComponent as IconOrder } from "../../../icons/icon-order.svg";
import { ReactComponent as IconAward } from "../../../icons/icon-award.svg";
import { PageTitle } from "../../../components/page-title/page-title";

const HomePageIntroducedVideoComponent = () => {
  return (
    <section id="gioi-thieu" className="video-promo py-5 gray-light-bg">
      <div className="container text-center">
        <PageTitle>
          Thành tựu của <span className="color-primary">PyCOM</span>
        </PageTitle>
        <p>Trong 5 năm chúng tôi đã đạt được những thành tựu nhất định</p>
        <div className="intro-video d-flex justify-content-center align-content-center w-100 mx-auto">
          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/cuRrtpyznYs"
              title="YouTube video"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageIntroducedVideoComponent;
