import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useFetchBlogs } from "../../hooks";
import { blogsState, loadBlogsState } from "../../states";
import { MwaDateTimeUtil, SessionState } from "../../utils";
import Skeleton from "react-loading-skeleton";
import { MwaImage } from "../../components";

const BlogsPage = () => {
  const fetchBlogs = useFetchBlogs();
  const blogList = useRecoilValue(blogsState);
  const loadingBlogs = useRecoilValue(loadBlogsState);

  useEffect(() => {
    switch (loadingBlogs) {
      case SessionState.idle:
      case SessionState.error:
        fetchBlogs();
        break;

      default:
        break;
    }
  }, [fetchBlogs, loadingBlogs]);

  const _renderContent = () => {
    switch (loadingBlogs) {
      case SessionState.success:
        return blogList.items.length > 0 ? (
          <div className="container d-flex flex-column justify-content-center">
            <div className="row">
              {blogList.items.map((blogInfo, index) => {
                return (
                  <div
                    key={index}
                    className="col-md-4 col-sm-6 col-12 color-white mb-4"
                    style={{ height: "40vh" }}
                  >
                    <a href={`/tin-tuc/${blogInfo.id}`} className="color-white">
                      <div className="position-relative h-100">
                        <MwaImage
                          src={blogInfo.thumbnail}
                          alt="thumbnail-img"
                          style={{
                            maxHeight: "40vh",
                            height: "auto",
                            width: "100%",
                          }}
                          fallbackSrc="/img/logo.png"
                        />
                        <div
                          className="position-absolute start-0 end-0 bottom-0 p-3"
                          style={{ background: "rgba(0, 0, 0, 0.5)" }}
                        >
                          <span className="mb-2 news-title fs-5">
                            {blogInfo.name}
                          </span>
                          <span
                            className="news-summary"
                            style={{ color: "rgb(209 213 219)" }}
                          >
                            {blogInfo.summary}
                          </span>
                          {blogInfo.createdAt && (
                            <span
                              className="mt-2"
                              style={{ fontSize: ".8rem" }}
                            >
                              {MwaDateTimeUtil.historyAgo(blogInfo.createdAt)}
                            </span>
                          )}
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <h5 className="color-white d-flex justify-content-center py-4">
            Chưa có tin tức
          </h5>
        );

      case SessionState.loading:
        return (
          <div className="container d-flex flex-column justify-content-center">
            <div className="row">
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="col-lg-6 mb-4">
                  <Skeleton height="40vh" />
                </div>
              ))}
            </div>
          </div>
        );

      case SessionState.error:
        return (
          <div className="d-flex justify-content-center py-3">
            <button
              className="btn btn-danger d-flex flex-column justify-content-center align-items-center"
              onClick={fetchBlogs}
            >
              <span>Lỗi tải tin tức: {blogList.error?.message}</span>
              <span> Thử lại</span>
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div id="blogs-page" className="main pt-4">
      {_renderContent()}
    </div>
  );
};

export default BlogsPage;
