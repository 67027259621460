import {
  EcommerceSolutionPageDetailFeaturesComponent,
  EcommerceSolutionPageHeaderComponent,
  EcommerceSolutionPageSummaryFeaturesComponent,
} from "./components";

const EcommerceSolutionPage = () => {
  return (
    <div id="ecommerce-solution-page" className="main">
      <EcommerceSolutionPageHeaderComponent />
      <EcommerceSolutionPageSummaryFeaturesComponent />
      <EcommerceSolutionPageDetailFeaturesComponent />
    </div>
  );
};

export default EcommerceSolutionPage;
