import {
  DmsSolutionPageHeaderComponent,
  DmsSolutionPageOpenApiSystemComponent,
  DmsSolutionPageProblemsComponent,
  DmsSolutionPageProcessComponent,
  DmsSolutionPageScreenshotsComponent,
  DmsSolutionPageSolutionsComponent,
  DmsSolutionPageZaloMiniAppComponent,
} from "./components";
import "./dms-solution.css";

const DmsSolutionPage = () => {
  return (
    <div id="dms-solution-page" className="main">
      <DmsSolutionPageHeaderComponent />
      <DmsSolutionPageProblemsComponent />
      <DmsSolutionPageSolutionsComponent />
      <DmsSolutionPageProcessComponent />
      <DmsSolutionPageZaloMiniAppComponent />
      <DmsSolutionPageOpenApiSystemComponent />
      <DmsSolutionPageScreenshotsComponent />
    </div>
  );
};

export default DmsSolutionPage;
