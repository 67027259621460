import {
  TmsSolutionPageFeaturesComponent,
  TmsSolutionPageHeaderComponent,
  TmsSolutionPageProblemsComponent,
  TmsSolutionPageSolutionsComponent,
} from "./components";
import "./tms-solution.css";

const TmsSolutionPage = () => {
  return (
    <div id="tms-solution-page" className="main">
      <TmsSolutionPageHeaderComponent />
      <TmsSolutionPageProblemsComponent />
      <TmsSolutionPageSolutionsComponent />
      <TmsSolutionPageFeaturesComponent />
    </div>
  );
};

export default TmsSolutionPage;
