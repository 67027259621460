import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";

enum _FeatureTabEnum {
  v1 = "Cho Khách hàng",
  v2 = "Cho Tài xế",
  v3 = "Cho Nhân viên",
  v4 = "Cho Admin",
}

const TmsSolutionPageFeaturesComponent = () => {
  // website viewport
  const [currentTab, setTab] = useState(_FeatureTabEnum.v1);

  // mobile viewport
  const [collapsedItems, setCollapsedItems] = useState(
    Object.values(_FeatureTabEnum).map((value, index) => ({
      id: index,
      tab: value,
      open: index === 0 ? true : false,
    }))
  );

  const _renderTabContent = (tab: _FeatureTabEnum) => {
    let imgSrcList: string[] = [];
    let cls = "";
    switch (tab) {
      case _FeatureTabEnum.v1:
        imgSrcList = [
          "client-app01.jpg",
          "client-app02.jpg",
          "client-app03.jpg",
          "client-app04.jpg",
        ];
        break;
      case _FeatureTabEnum.v2:
        imgSrcList = [
          "driver-app01.jpg",
          "driver-app02.jpg",
          "driver-app03.jpg",
          "driver-app04.jpg",
          "driver-app05.jpg",
          "driver-app06.jpg",
        ];
        break;
      case _FeatureTabEnum.v3:
        imgSrcList = [
          "staff-app01.jpg",
          "staff-app02.jpg",
          "staff-app03.jpg",
          "staff-app04.jpg",
          "staff-app05.jpg",
          "staff-app06.jpg",
          "staff-app07.jpg",
          "staff-app08.jpg",
          "staff-app09.jpg",
        ];
        break;
      case _FeatureTabEnum.v4:
        cls = tab === _FeatureTabEnum.v4 ? "img-full" : "";
        imgSrcList = [
          "admin-01.png",
          "admin-02.png",
          "admin-03.png",
          "admin-04.png",
          "admin-05.png",
        ];
        break;
      default:
        break;
    }

    return (
      imgSrcList.length > 0 && (
        <Splide
          options={{
            type: "loop",
            autoplay: true,
            pauseOnHover: false,
            updateOnMove: true,
            perPage: 1,
            gap: "1rem",
            pagination: true,
            focus: "center",
            breakpoints: {
              480: {
                perPage: 1,
              },
            },
          }}
        >
          {[...imgSrcList].map((src, index) => {
            return (
              <SplideSlide key={index}>
                <img
                  src={`./img/screenshots/tms/${src}`}
                  className={`img-fluid screenshot-tms ${cls}`}
                  alt="screenshots"
                />
              </SplideSlide>
            );
          })}
        </Splide>
      )
    );
  };

  const _renderMobileTabContent = (tab: _FeatureTabEnum) => {
    let imgSrcList: string[] = [];
    switch (tab) {
      case _FeatureTabEnum.v1:
        imgSrcList = Array.from({length: 10}, (_, i) => {
          const number = i + 1 < 10 ? '0' : '';
          return `mobile-client-app${number}${i + 1}.png`;
        });
        break;
      case _FeatureTabEnum.v2:
        imgSrcList = Array.from({length: 15}, (_, i) => {
          const number = i + 1 < 10 ? '0' : '';
          return `mobile-driver-app${number}${i + 1}.png`;
        });
        break;
      case _FeatureTabEnum.v3:
        imgSrcList = Array.from({length: 20}, (_, i) => {
          const number = i + 1 < 10 ? '0' : '';
          return `mobile-staff-app${number}${i + 1}.png`;
        });
        break;
      case _FeatureTabEnum.v4:
        imgSrcList = Array.from({length: 5}, (_, i) => {
          const number = i + 1 < 10 ? '0' : '';
          return `admin-${number}${i + 1}.png`;
        });
        break;
      default:
        break;
    }

    return (
      imgSrcList.length > 0 && (
        <Splide
          options={{
            type: "loop",
            autoplay: true,
            pauseOnHover: false,
            updateOnMove: true,
            perPage: 1,
            gap: "1rem",
            pagination: true,
            focus: "center",
            breakpoints: {
              480: {
                perPage: 1,
              },
            },
          }}
        >
          {[...imgSrcList].map((src, index) => {
            return (
              <SplideSlide key={index}>
                <img
                  src={`./img/screenshots/tms/${src}`}
                  className="img-fluid screenshot-tms"
                  alt="screenshots"
                />
              </SplideSlide>
            );
          })}
        </Splide>
      )
    );
  };

  const _renderTabBarIcon = (tab: _FeatureTabEnum) => {
    let srcIcon = "";
    switch (tab) {
      case _FeatureTabEnum.v1:
        srcIcon = "./img/icons/customer.png";
        break;
      case _FeatureTabEnum.v2:
        srcIcon = "./img/icons/driver.png";
        break;
      case _FeatureTabEnum.v3:
        srcIcon = "./img/icons/staffs.png";
        break;
      case _FeatureTabEnum.v4:
        srcIcon = "./img/icons/staffs.png";
        break;
      default:
        return <></>;
    }
    return <img src={srcIcon} alt="icon" style={{ height: "4vh" }} />;
  };

  const _renderCardContentForMobileView = () => {
    return (
      <div className="d-flex flex-column">
        {collapsedItems.map((item, index) => (
          <div key={index} className="card p-4 mb-4">
            <div
              aria-controls={`tmsFeatureTabEnum_${index}`}
              aria-expanded={item.open}
              onClick={() => {
                setCollapsedItems(
                  [...collapsedItems].map((e) =>
                    e.id === item.id ? { ...e, open: !item.open } : e
                  )
                );
              }}
            >
              <div className="d-flex justify-content-between">
                <span className="ms-3 fw-bold">{item.tab}</span>
                <i
                  className={`fa ${
                    item.open ? "fa-chevron-up" : "fa-chevron-down"
                  } d-flex align-items-center`}
                  aria-hidden="true"
                ></i>
              </div>
            </div>

            <Collapse in={item.open}>
              <div id={`tmsFeatureTabEnum_${index}`} className="mt-4">
                {_renderMobileTabContent(item.tab)}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section id="tinh-nang">
      <div className="container py-5">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="Các Tính năng nổi bật của PyCOM TMS" />
            </div>
          </div>
          <BookADemoButton className="mt-3"/>
        </div>

        {/* Viewport for Web */}
        <div className="d-lg-block d-none">
          <div className="row justify-content-center tabs tab-fs-sm">
            {Object.values(_FeatureTabEnum).map((value, index) => {
              const cls = index === 0 ? "left" : index === Object.values(_FeatureTabEnum).length - 1 ? "right" : "center";
              return (
                <div
                  key={index}
                  className={`col-lg-3 col-md-3 col-6 mx-0 tab-item text-center ${
                    currentTab === value ? "active" : ""
                  } ${cls}`}
                  onClick={() => {
                    if (currentTab !== value) {
                      setTab(value);
                    }
                  }}
                >
                  {_renderTabBarIcon(value)}
                  <span className="ms-0">{value}</span>
                </div>
              )
            })}
          </div>
          <div className="row">
            <div className="col-12 col-md-10 d-flex mx-auto">{_renderTabContent(currentTab)}</div>
          </div>
        </div>

        {/* Viewport for Mobile */}
        <div className="d-lg-none d-block">
          {_renderCardContentForMobileView()}
        </div>
      </div>
    </section>
  );
};

export default TmsSolutionPageFeaturesComponent;
