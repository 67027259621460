import React, {ReactNode} from "react";

interface PageTitleProps {
  title?: string;
  children?: ReactNode;
  className?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, children, className}) => {
  return (
    <h4 className={`color-d-grey mb-0 main-title ${className || ""}`}>{title || children}</h4>
  )
}
