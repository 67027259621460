import axios from "axios";
import { HttpErrorResponse } from "../../models";

export class BaseApiConstant {
  static baseApiUrl: string = "https://delivery-system-api.pycom.vn/api/";
}

export class Endpoint {
  // BLOGS
  static _prefixBlogs: string = "blogs";
  static pycomBlogs: string = `${this._prefixBlogs}/pycom`;
  static pycomBlogById(id: string): string {
    return `${this._prefixBlogs}/${id}`;
  }
}

export function handleError(error: any): HttpErrorResponse {
  if (error === null) throw new Error("Unrecoverable error!! Error is null!");
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const statusCode = error.response?.status;
      if (statusCode === 404) {
        return new HttpErrorResponse(
          "Không tìm thấy đường dẫn xử lý tác vụ này"
        );
      } else if (statusCode === 401) {
        return new HttpErrorResponse("Không có quyền xử lý tác vụ này");
      }

      if (error.response.data?.errors) {
        const entries = Object.entries(error.response.data?.errors);
        let msg = "";
        entries.forEach(([key, value]) => {
          if (Array.isArray(value)) {
            msg = !msg.length
              ? value.join(", ")
              : msg + `\n${value.join(", ")}`;
          } else {
            msg = !msg.length
              ? JSON.stringify(value)
              : msg + `\n${JSON.stringify(value)}`;
          }
        });
        return new HttpErrorResponse(msg, error.response.data);
      }
      return new HttpErrorResponse(error.message, error.response.data);
    } else if (error.request) {
      console.log(">error.request: ", error.request);
      return new HttpErrorResponse(error.message, error.request);
    } else {
      console.log(error.message);
      return new HttpErrorResponse(error.message);
    }
  }
  return new HttpErrorResponse("Lỗi hệ thống (chưa xử lý)");
}
