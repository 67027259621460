import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MwaDateTimeUtil, SessionState } from "../../utils";
import { BlogService } from "../../services";
import { BlogModel } from "../../models";
import Skeleton from "react-loading-skeleton";

const BlogDetailPage = () => {
  const { id } = useParams();
  const [blogDetail, setBlogDetail] = useState<BlogModel>();
  const [loadingBlogDetail, setLoadingBlogDetail] = useState(SessionState.idle);

  useEffect(() => {
    fetchBlogDetail();
  }, []);

  const fetchBlogDetail = async () => {
    setLoadingBlogDetail(SessionState.loading);

    const response = await BlogService.fetchBlogById(id as string);

    if (response.isSuccess) {
      setLoadingBlogDetail(SessionState.success);
    } else {
      setLoadingBlogDetail(SessionState.error);
    }
    setBlogDetail(response);
  };

  const _renderContent = () => {
    switch (loadingBlogDetail) {
      case SessionState.success:
        return blogDetail ? (
          <>
            <ol className="breadcrumb">
              <li className="">
                <a href="/tin-tuc">Tất cả</a>
              </li>
              <li className="mx-2">&gt;</li>
              <li className="breadcrumb-item active">Chi tiết</li>
            </ol>
            <h3 className="m-0">{blogDetail.name}</h3>
            {blogDetail.createdAt && (
              <span style={{ fontSize: ".8rem" }}>
                {MwaDateTimeUtil.historyAgo(blogDetail.createdAt)}
              </span>
            )}
            <p>{blogDetail.summary}</p>
            <div
              dangerouslySetInnerHTML={{ __html: blogDetail.content ?? "" }}
            />
          </>
        ) : (
          <></>
        );

      case SessionState.loading:
        return (
          <div className="container d-flex flex-column justify-content-center">
            <div className="row">
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="col-lg-6 mb-4">
                  <Skeleton height="40vh" />
                </div>
              ))}
            </div>
          </div>
        );

      case SessionState.error:
        return (
          <div className="d-flex justify-content-center py-3">
            <button
              className="btn btn-danger d-flex flex-column justify-content-center align-items-center"
              onClick={fetchBlogDetail}
            >
              <span>
                Lỗi tải chi tiết tin tức: {blogDetail?.error?.message}
              </span>
              <span> Thử lại</span>
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div id="blog-detail-page" className="main pt-4">
      <div className="container">{_renderContent()}</div>
    </div>
  );
};

export default BlogDetailPage;
