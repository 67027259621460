import { ContactUsComponent } from "../../components";
import {
  HomePageBlogsComponent,
  HomePageHeaderComponent,
  HomePageIntroducedVideoComponent,
  HomePageOurClientsComponent,
  HomePageProductsComponent,
  HomePageScreenshotsComponent,
  HomePageSolutionsComponent,
  HomePageWhyChoosingUsComponent,
} from "./components";
import "./home.css";

const HomePage = () => {
  return (
    <div id="home-page" className="main">
      <HomePageHeaderComponent />
      <HomePageProductsComponent />
      <HomePageSolutionsComponent />
      <HomePageIntroducedVideoComponent />
      <HomePageScreenshotsComponent />
      <HomePageWhyChoosingUsComponent />
      <HomePageOurClientsComponent />
      <HomePageBlogsComponent />
      <ContactUsComponent />
    </div>
  );
};

export default HomePage;
