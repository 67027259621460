import { useNavigate } from "react-router-dom";
import { ReactComponent as IconTMS } from "../../../icons/icon-tms.svg";
import { ReactComponent as IconDMS } from "../../../icons/icon-dms.svg";
import { ReactComponent as IconApp } from "../../../icons/icon-app.svg";
import { PageTitle } from "../../../components/page-title/page-title";

const HomePageProductsComponent = () => {
  const navigate = useNavigate();

  const _cardInfo = ({
    title,
    Icon,
    isIcon,
    image,
    description,
    onShowMore: onShownMore,
  }: {
    title: string;
    isIcon?: boolean;
    image?: string;
    Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    description: string;
    onShowMore: () => void;
  }) => {
    return (
      <div className="col-lg-4 col-md-6">
        <div
          className="card text-center single-pricing-pack py-4 px-5 h-100"
          onClick={onShownMore}
        >
          <div className="pricing-img mb-4 flex-grow-0">
            {isIcon && Icon && <Icon />}
            {!isIcon && image && (
              <img
                src={image}
                alt={title}
                className="img-fluid product-logo"
              ></img>
            )}
          </div>
          <h5 className="mb-2 flex-grow-0 font-weight-bolder">{title}</h5>
          <div className="p-0 flex-grow-1 d-flex flex-column">
            <p className="text-sm pricing-feature-list flex-grow-1">
              {description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="san-pham" className="background-shape-right pt-5">
      <div className="container">
        <div className="row justify-content-center mt-lg-2 mt-0">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center">
                <PageTitle title="Sản phẩm" className="mb-2" />
                <p>Những sản phẩm nổi bật của PyCOM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4">
          {_cardInfo({
            title: "Quản lý Vận tải (TMS)",
            Icon: IconTMS,
            image: "img/logo-tms.png",
            isIcon: false,
            description:
              "Giải pháp Vận tải toàn diện cho các công ty vận tải và các doanh nghiệp sản xuất / phân phối.",
            onShowMore: () => {
              navigate("/giai-phap-van-tai-tms");
            },
          })}
          {_cardInfo({
            title: "Quản lý Phân phối (DMS)",
            Icon: IconDMS,
            image: "img/logo-dms.png",
            isIcon: false,
            description:
              "Giải pháp quản lý Sale ngoài trị trường, quản lý đơn hàng",
            onShowMore: () => {
              navigate("/giai-phap-phan-phoi-dms");
            },
          })}
          {_cardInfo({
            title: "Zalo Mini Apps service",
            isIcon: false,
            Icon: IconApp,
            image: "img/logo-zalo-mini-app.png",
            description:
              "Giải pháp xây dựng Mini App doanh nghiệp trực tiếp sử dụng trên nền tảng Zalo",
            onShowMore: () => {
              navigate("/giai-phap-zalo-apps");
            },
          })}
          {_cardInfo({
            title: "PyCOM E-Commerce",
            isIcon: false,
            Icon: IconApp,
            image: "img/logo-ecommerce.png",
            description: "Cung cấp giải pháp bàn hàng toàn diện",
            onShowMore: () => {
              navigate("/giai-phap-ecommerce");
            },
          })}
          {_cardInfo({
            title: "Sàn Vận Tải",
            isIcon: false,
            Icon: IconApp,
            image: "img/logo-san-van-tai.png",
            description: "Xe tìm hàng - Hàng tìm xe",
            onShowMore: () => window.open("https://sanvantai.vn/", "_blank"),
          })}
        </div>
        <div className="row justify-content-center g-4 mt-2">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center">
                <p className="mb-0">
                  Bạn cần các giải pháp riêng biệt cho doanh nghiệp?
                  <a href="#lien-he" className="color-primary ms-1 btn-lien-he">
                    Liên hệ với chúng tôi
                  </a>
                </p>
              </div>
            </div>
          </div>
          <span> </span>
        </div>
      </div>
    </section>
  );
};

export default HomePageProductsComponent;
