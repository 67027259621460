import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";
import { PageTitle } from "../../../components/page-title/page-title";
import { ecommerceDetailFeaturesJson } from "../../../contents";

const EcommerceSolutionPageDetailFeaturesComponent = () => {
  return (
    <section id="tinh-nang-chi-tiet">
      <div className="container py-5">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="Chi tiết các tính năng" />
            </div>
          </div>
          <BookADemoButton className="mt-3" />
        </div>
        <div>
          {ecommerceDetailFeaturesJson.map((item, index) => {
            const even = index % 2 === 0;
            return (
              <div className="row mb-4 tms-items" key={index}>
                <div
                  className={`col-12 col-md-6 d-flex flex-column justify-content-center ${
                    even ? "order-md-first" : ""
                  }`}
                >
                  <h4>{item.title}</h4>
                  <ul className="list-style-disc">
                    {item.contents.map((childItem, i) => {
                      return <li key={i}>{childItem}</li>;
                    })}
                  </ul>
                </div>
                <div
                  className={`col-12 col-md-6 d-flex order-last ${
                    even ? "align-self-end" : "order-md-first align-self-start"
                  }`}
                >
                  <img
                    src={item.imgSrc}
                    alt={item.title}
                    className="img-fluid"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default EcommerceSolutionPageDetailFeaturesComponent;
