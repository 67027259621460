import {
  Link,
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import {
  FooterComponent,
  NavBarComponent,
  RegisterFormModal,
} from "./components";
import {
  BlogDetailPage,
  BlogsPage,
  DmsSolutionPage,
  EcommerceSolutionPage,
  HomePage,
  PycomSolutionsPrivacyPolicyPage,
  TmsSolutionPage,
  ZaloSolutionPage,
} from "./pages";

import "@splidejs/react-splide/css";
import "react-loading-skeleton/dist/skeleton.css";
import { RecoilRoot } from "recoil";
import CallNowAction from "./components/call-now-action/call-now-action";
import redirectJson from "./redirect.json";

const NotFound = () => (
  <div
    className="not-found"
    style={{
      margin: "50px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <img src="/img/404.jpg" alt="not-found" style={{ maxWidth: "400px" }} />
    <Link to="/" className="link-home">
      Trang chủ
    </Link>
  </div>
);

function App() {
  return (
    <RecoilRoot>
      <Router>
        <NavBarComponent />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/giai-phap-van-tai-tms" element={<TmsSolutionPage />} />
          <Route
            path="/giai-phap-phan-phoi-dms"
            element={<DmsSolutionPage />}
          />
          <Route path="/giai-phap-zalo-apps" element={<ZaloSolutionPage />} />
          <Route
            path="/giai-phap-ecommerce"
            element={<EcommerceSolutionPage />}
          />
          <Route path="/tin-tuc" element={<BlogsPage />} />
          <Route path="/tin-tuc/:id" element={<BlogDetailPage />} />
          <Route
            path="/chinh-sach-bao-mat"
            element={<PycomSolutionsPrivacyPolicyPage />}
          />
          {Object.keys(redirectJson).map((key) => {
            return (
              <Route
                key={key}
                path={key as string}
                // @ts-ignore
                element={<Navigate to={redirectJson[key].newPath} replace />}
              />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <RegisterFormModal applicationName="Đăng ký trải nghiệm PyCOM TMS - Website" />
        <FooterComponent />
        <CallNowAction />
      </Router>
    </RecoilRoot>
  );
}

export default App;
