import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";

const DmsSolutionPageSolutionsComponent = () => {
  const _cardInfo = ({
    title,
    imgSrc,
    textList,
  }: {
    title: string;
    imgSrc: string;
    textList: string[];
  }) => {
    return (
      <div className="col-lg-4 col-md-6">
        <div className="card single-promo-card single-promo-hover h-100">
          <div className="card-body d-flex flex-column align-items-center">
            <img
              src={imgSrc}
              alt="iconSrc"
              className="rounded-3"
              style={{ width: "100%" }}
            />
            <div className="pt-2 pb-3">
              <h5 className="pb-3 text-center border-bottom border-3">
                {title}
              </h5>
              {textList.map((value, index) => {
                return (
                  <div key={index} className="d-flex align-items-start mb-4">
                    <div className="me-3">
                      <img
                        className="icon-img"
                        src="./img/dms/tick-circle.png"
                        alt="tick-circle"
                        width="30rem"
                      />
                    </div>
                    <span>{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="giai-phap">
      <div className="container">
        <div className="row justify-content-center my-5">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center mb-4">
              <div className="section-heading text-center">
                <PageTitle title="Giải pháp mà PyCOM DMS mang lại cho bạn" />
                <p className="page-sub-title mt-2">
                  Với hơn 10 năm kinh nghiệm trong lĩnh vực phần mềm và giải
                  pháp số hóa. PyCOM sẵn sàng đồng hành cùng doanh nghiệp trong
                  vấn để đưa công nghệ vào tối ưu vận hành và quản lý
                </p>
              </div>
              <BookADemoButton />
            </div>
          </div>
          <div className="row g-4">
            {_cardInfo({
              title: "Quản lý nhân viên Sales bán hàng ngoài thị trường",
              imgSrc: "./img/dms/sc2-img-1.png",
              textList: [
                "Sắp xếp phân bổ lịch làm việc cho Sale thông qua tuyến bán hàng",
                "Giám sát thời gian, lộ trình, vị trí và năng suất làm việc của Sale",
                "Quản lý KPI theo thời gian thực",
                "Các tính năng thông minh hỗ trợ Sale ngoài thị trường",
              ],
            })}
            {_cardInfo({
              title: "Tính năng đặt hàng dành cho Sale thị trường",
              imgSrc: "./img/dms/sc2-img-2.png",
              textList: [
                "Lên đơn hàng thuận tiện thông qua ứng dụng điện thoại",
                "Quản lý đơn hàng, tình trạng đơn hàng",
                "Báo cáo doanh số bán hàng, KPI",
                "Quản lý chương trình khuyến mãi và áp dụng khuyến mãi tự động",
              ],
            })}
            {_cardInfo({
              title: "Hỗ trợ các công cụ Marketing cho cửa hàng",
              imgSrc: "./img/dms/sc2-img-3.png",
              textList: [
                "Quản lý khuyến mãi, trả thưởng",
                "Quản lý khuyến mãi hàng trưng bày",
                "Theo dõi doanh thu và xếp hạng thành viên",
                "Giám sát cửa hiệu, trưng bày sản phẩm",
              ],
            })}
            {_cardInfo({
              title:
                "Ứng dụng Zalo mini app hỗ trợ lên đơn hàng cho chủ cửa hàng",
              imgSrc: "./img/dms/sc2-img-4.png",
              textList: [
                "Không cần đăng ký, đăng nhập hay cài đặt ứng dụng",
                "Sử dụng nền tảng Zalo để lên đơn hàng thuận tiện",
                "Tự động phân bố đơn hàng theo tuyến và nhân viên Sale",
                "Cập nhật và theo dõi trạng thái đơn hàng",
              ],
            })}
            {_cardInfo({
              title: "Quản lý khách hàng",
              imgSrc: "./img/dms/sc2-img-5.png",
              textList: [
                "Quản lý danh sách khách hàng",
                "Quản lý vị trí lộ trình khách hàng trên tuyến bán hàng",
                "Ghi nhận góp ý và phản hồi của khách hàng",
                "Theo dõi độ phủ khách hàng thông qua Google Map",
              ],
            })}
            {_cardInfo({
              title: "Quản lý nhà phân phối",
              imgSrc: "./img/dms/sc2-img-6.png",
              textList: [
                "Quản lý kho, xuất nhập kho",
                "Quản lý tồn kho",
                "Quản lý doanh số, báo cáo bán hàng",
                "Quản lý mua hàng nhà phân phối",
              ],
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DmsSolutionPageSolutionsComponent;
