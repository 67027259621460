const TmsSolutionPageHeaderComponent = () => {
  return (
    <section
      id="tms-solution-page-header"
      className="hero-section py-5 background-img"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between g-4">
          <div className="col-md-6">
            <div className="hero-content-left text-white mt-5">
              <p className="lead mb-1">Hệ thống quản lý vận tải toàn diện</p>
              <h1 className="text-white fw-bold">TMS by PyCOM</h1>
              <ul className="list-style-disc ps-4">
                <li>
                  Sứ mệnh số hóa cho nghành logistic dựa trên nền tảng công nghệ
                  tiên tiến và thông minh.
                </li>
                <li>
                  Giải quyết triệt để những khó khăn trong vấn đề quản lý và vận
                  hành vận tải.
                </li>
                <li>
                  Tăng hiệu quả vận hành, kiểm soát và cắt giảm tối đa chi phí.
                </li>
                <li>
                  Chìa khóa để xây dựng quy trình tự động, hạn chế phụ thuộc vào
                  con người để dễ dàng mở rộng quy mô và tăng trưởng.
                </li>
              </ul>
              <a href="#van-de" className="btn btn-light page-scroll my-5">
                Tìm hiểu ngay
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/cuRrtpyznYs"
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TmsSolutionPageHeaderComponent;
