import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { PageTitle } from "../../../components/page-title/page-title";

enum _ScreenshotTabEnum {
  all = "Tất cả",
  tms = "TMS",
  dms = "DMS",
  zaloMiniApps = "Zalo Mini Apps",
}

const HomePageScreenshotsComponent = () => {
  // website viewport
  const [currentTab, setTab] = useState(_ScreenshotTabEnum.all);

  // mobile viewport
  const [collapsedItems, setCollapsedItems] = useState(
    Object.values(_ScreenshotTabEnum).map((value, index) => ({
      id: index,
      tab: value,
      open: index === 0 ? true : false,
    }))
  );

  const _tmsScreenshotSrc = [
    "screenshots/tms/admin-01.png",
    "screenshots/tms/admin-02.png",
    "screenshots/tms/admin-03.png",
    "screenshots/tms/admin-04.png",
    "screenshots/tms/admin-05.png",
  ];
  const _dmsScreenshotSrc = ["dms/feature-01.jpg", "dms/feature-02.jpg"];
  const _dmsMobileScreenshotSrc = [
    "dms/mobile-feature-01.png",
    "dms/mobile-feature-02.png",
    "dms/mobile-feature-03.png",
    "dms/mobile-feature-04.png",
    "dms/mobile-feature-05.png",
    "dms/mobile-feature-06.png",
  ];
  const _zaloMiniAppsScreenshotSrc = [
    "screenshots/zalo-mini-apps/zalo-mini-app-1.jpg",
    "screenshots/zalo-mini-apps/zalo-mini-app-2.jpg",
  ];
  const _zaloMiniAppsMobileScreenshotSrc = [
    "dms/dms-app-01.png",
    "dms/dms-app-02.png",
    "dms/dms-app-03.png",
    "dms/dms-app-04.png",
    "dms/dms-app-05.png",
    "dms/dms-app-06.png",
  ];

  const _renderTab = () => {
    return (
      <div className="row justify-content-center tabs mb-4">
        {Object.values(_ScreenshotTabEnum).map((value, index) => {
          const cls =
            index === 0
              ? "left"
              : index === Object.values(_ScreenshotTabEnum).length - 1
              ? "right"
              : "center";
          return (
            <div
              key={index}
              className={`col-lg-auto col-md-4 col-6 mx-lg-4 mx-0 tab-item ${
                currentTab === value ? "active" : ""
              } ${cls}`}
              onClick={() => {
                if (currentTab !== value) {
                  setTab(value);
                }
              }}
            >
              {value}
            </div>
          );
        })}
      </div>
    );
  };

  const _renderTabContent = () => {
    let imgSrcList: string[] = [];
    switch (currentTab) {
      case _ScreenshotTabEnum.all:
        imgSrcList = [
          ..._tmsScreenshotSrc,
          ..._dmsScreenshotSrc,
          ..._zaloMiniAppsScreenshotSrc,
        ];
        break;
      case _ScreenshotTabEnum.tms:
        imgSrcList = [..._tmsScreenshotSrc];
        break;
      case _ScreenshotTabEnum.dms:
        imgSrcList = [..._dmsScreenshotSrc];
        break;
      case _ScreenshotTabEnum.zaloMiniApps:
        imgSrcList = [..._zaloMiniAppsScreenshotSrc];
        break;
      default:
        break;
    }

    return (
      imgSrcList.length > 0 && (
        <div className="d-lg-block d-none w-75 mx-auto">
          <Splide
            options={{
              type: "loop",
              autoplay: true,
              pauseOnHover: false,
              updateOnMove: true,
              perPage: 1,
              gap: "1rem",
              pagination: true,
              focus: "center",
              autoHeight: true,
              breakpoints: {
                480: {
                  perPage: 1,
                },
                960: {
                  perPage: 1,
                },
                1200: {
                  perPage: 1,
                },
              },
            }}
          >
            {[...imgSrcList].map((src, index) => {
              return (
                <SplideSlide key={index}>
                  <img
                    src={`./img/${src}`}
                    className="img-fluid"
                    alt="screenshots"
                  />
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      )
    );
  };

  const _renderCardContentForMobileView = () => {
    return (
      <div className="d-flex flex-column">
        {collapsedItems.map((item, index) => (
          <div key={index} className="card p-4 mb-4">
            <div
              aria-controls={`screenshotTabEnum_${index}`}
              aria-expanded={item.open}
              onClick={() => {
                setCollapsedItems(
                  [...collapsedItems].map((e) =>
                    e.id === item.id ? { ...e, open: !item.open } : e
                  )
                );
              }}
            >
              <div className="d-flex justify-content-between">
                <span className="ms-3 fw-bold">{item.tab}</span>
                <i
                  className={`fa ${
                    item.open ? "fa-chevron-up" : "fa-chevron-down"
                  } d-flex align-items-center`}
                  aria-hidden="true"
                ></i>
              </div>
            </div>

            <Collapse in={item.open}>
              <div id={`screenshotTabEnum_${index}`} className="mt-4">
                {_renderCardBodyForMobileView(item.tab)}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    );
  };

  const _renderCardBodyForMobileView = (tab: _ScreenshotTabEnum) => {
    let imgSrcList: string[] = [];
    switch (tab) {
      case _ScreenshotTabEnum.all:
        imgSrcList = [
          ..._tmsScreenshotSrc,
          ..._dmsMobileScreenshotSrc,
          ..._zaloMiniAppsMobileScreenshotSrc,
        ];
        break;
      case _ScreenshotTabEnum.tms:
        imgSrcList = [..._tmsScreenshotSrc];
        break;
      case _ScreenshotTabEnum.dms:
        imgSrcList = [..._dmsMobileScreenshotSrc];
        break;
      case _ScreenshotTabEnum.zaloMiniApps:
        imgSrcList = [..._zaloMiniAppsMobileScreenshotSrc];
        break;
      default:
        break;
    }

    return (
      imgSrcList.length > 0 && (
        <Splide
          options={{
            type: "loop",
            autoplay: true,
            pauseOnHover: false,
            updateOnMove: true,
            perPage: 1,
            gap: "1rem",
            pagination: true,
            focus: "center",
            breakpoints: {
              480: {
                perPage: 1,
              },
              960: {
                perPage: 1,
              },
              1200: {
                perPage: 1,
              },
            },
          }}
        >
          {[...imgSrcList].map((src, index) => {
            return (
              <SplideSlide key={index}>
                <img
                  src={`./img/${src}`}
                  className="img-fluid"
                  alt="screenshots"
                />
              </SplideSlide>
            );
          })}
        </Splide>
      )
    );
  };

  return (
    <section
      id="home-page-screenshots"
      className="screenshots-section py-5 background-shape-right"
    >
      <div className="container">
        <div className="row justify-content-center mb-3">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="Hình ảnh ứng dụng" className="mb-2" />
              <p>
                PyCOM TMS với lợi thế về số hóa toàn bộ quy trình vận tải bằng
                những công nghệ mới nhất
              </p>
            </div>
          </div>
        </div>

        {/* Viewport for Web */}
        <div className="d-lg-block d-none">
          {_renderTab()}
          {/*<div className="row d-flex justify-content-center align-content-center">*/}
          {/*  <div className="col-8">*/}
          {_renderTabContent()}
          {/*</div>*/}
          {/*</div>*/}
        </div>

        {/* Viewport for Mobile */}
        <div className="d-lg-none d-block">
          {_renderCardContentForMobileView()}
        </div>
      </div>
    </section>
  );
};

export default HomePageScreenshotsComponent;
