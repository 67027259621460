import { Splide, SplideSlide } from "@splidejs/react-splide";
import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";
import { PageTitle } from "../../../components/page-title/page-title";

const DmsSolutionPageScreenshotsComponent = () => {
  return (
  <section id="dms-solution-page-features" 
    style={{ background: "#6196FE" }} className="p-5">
      <div className="container">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="Các Tính năng nổi bật của PyCOM DMS" />
            </div>
          </div>
          <BookADemoButton className="mt-3"/>
        </div>

        <div className="d-lg-block d-none mx-auto">
          <div className="row">
            <div className="col-12 col-md-10 d-flex mx-auto">
              <Splide
                options={{
                  type: "loop",
                  autoplay: true,
                  pauseOnHover: false,
                  updateOnMove: true,
                  perPage: 1,
                  gap: "1rem",
                  pagination: true,
                  focus: "center",
                  breakpoints: {
                    480: {
                      perPage: 1,
                    },
                    960: {
                      perPage: 1,
                    },
                    1200: {
                      perPage: 1,
                    },
                  },
                }}
              >
                {[
                  "./img/dms/feature-01.jpg",
                  "./img/dms/feature-02.jpg",
                ].map((src, index) => {
                  return (
                    <SplideSlide key={index}>
                      <img src={src} className="img-fluid" alt="screenshots" />
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          </div>
        </div>


        {/* Viewport for Mobile */}
        <div className="d-lg-none d-block">
          <div className="row">
            <div className="col-12 d-flex mx-auto">
              <Splide
                options={{
                  type: "loop",
                  autoplay: true,
                  pauseOnHover: false,
                  updateOnMove: true,
                  perPage: 1,
                  gap: "1rem",
                  pagination: true,
                  focus: "center",
                  breakpoints: {
                    480: {
                      perPage: 1,
                    },
                    960: {
                      perPage: 1,
                    },
                    1200: {
                      perPage: 1,
                    },
                  },
                }}
              >
                {[
                  "./img/dms/mobile-feature-01.png",
                  "./img/dms/mobile-feature-02.png",
                  "./img/dms/mobile-feature-03.png",
                  "./img/dms/mobile-feature-04.png",
                  "./img/dms/mobile-feature-05.png",
                  "./img/dms/mobile-feature-06.png",
                ].map((src, index) => {
                  return (
                    <SplideSlide key={index}>
                      <img src={src} className="img-fluid" alt="screenshots" />
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DmsSolutionPageScreenshotsComponent;
