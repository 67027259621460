import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";

const DmsSolutionPageProcessComponent = () => {
  const _stepInfo = ({
    title,
    iconSrc,
    description,
  }: {
    title: string;
    iconSrc: string;
    description: string;
  }) => {
    return (
      <div className="col-md-6 d-flex flex-column align-items-start mb-4">
        <img src={iconSrc} alt="iconSrc" className="d-block mx-auto process-steps img-fluid" />
        {/*<div className="pt-2 pb-3 text-center w-100">*/}
        {/*  <h5>{title}</h5>*/}
        {/*  <span>{description}</span>*/}
        {/*</div>*/}
      </div>
    );
  };

  const _afterProcessInfo = ({
    iconSrc,
    description,
  }: {
    iconSrc: string;
    description: string;
  }) => {
    return (
      <div className="d-flex align-items-center mb-4">
        <img src={iconSrc} alt="iconSrc" width="50rem" />
        <div className="ms-4">
          <span className="fs-5">{description}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <section
        id="quy-trinh"
        className="background-shape-right"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="d-flex flex-column align-items-center mb-4">
                <div className="section-heading text-center">
                  <PageTitle title="Quy trình triển khai hệ thống PyCOM DMS" />
                  <p className="page-sub-title mt-2">
                    Triển khai DMS yêu cầu sự chuẩn bị kỹ lưỡng để đảm bảo hệ
                    thống DMS triển khai thành công và đáp ứng được nhu cầu của
                    doanh nghiệp
                  </p>
                </div>
                <BookADemoButton />
              </div>
              <div className="p-5 row">
                {_stepInfo({
                  title: "Bước 1",
                  iconSrc: "./img/dms/sc4-img-1.png",
                  description: "Đánh giá nhu cầu & Phân tích nghiệp vụ",
                })}
                {_stepInfo({
                  title: "Bước 2",
                  iconSrc: "./img/dms/sc4-img-2.png",
                  description:
                    "Cài đặt, cấu hình, lập trình, kiểm tra và chạy thử hệ thống PyCOM DMS",
                })}
                {_stepInfo({
                  title: "Bước 3",
                  iconSrc: "./img/dms/sc4-img-3.png",
                  description: "Nhập dữ liệu & Triển khai thực tế",
                })}
                {_stepInfo({
                  title: "Bước 4",
                  iconSrc: "./img/dms/sc4-img-4.png",
                  description: "Đào tạo sử dụng hệ thống",
                })}
              </div>
            </div>
            <div className="col-lg-4 d-lg-block d-none">
              <img
                src="./img/dms/sc3-img-5.jpg"
                alt="banner"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="gray-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-lg-block d-none py-5">
              <img src="./img/dms/bg-sc5.jpg" alt="banner" className="w-100" />
            </div>
            <div className="col-lg-6 p-5">
              <div className="d-flex flex-column align-items-center mb-4">
                <div className="section-heading text-center">
                  <PageTitle title="Đồng hành sau khi triển khai" />
                  <p className="page-sub-title mt-2">
                    Đội ngũ PyCOM luôn hỗ trợ & đồng hành cùng khách hàng sau
                    khi đã triển khai sử dụng, để hệ thống luôn mang lại hiệu
                    suất cho việc kinh doanh
                  </p>
                </div>
                <BookADemoButton />
              </div>
              <div className="row">
                {_afterProcessInfo({
                  iconSrc: "./img/dms/sc5-img-1.png",
                  description:
                    "Hỗ trợ trong suốt quá trình sử dụng, bảo trì & xử lý các vấn đề của hệ thống",
                })}
                {_afterProcessInfo({
                  iconSrc: "./img/dms/sc5-img-2.png",
                  description:
                    "Tư vấn & triển khai các giải pháp công nghệ tạo hiệu quả cho việc kinh doanh",
                })}
                {_afterProcessInfo({
                  iconSrc: "./img/dms/sc5-img-3.png",
                  description:
                    "Đồng hành xuyên suốt cùng khách hàng, mang lại hiệu suất kinh doanh & tăng trưởng",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DmsSolutionPageProcessComponent;
