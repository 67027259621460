import {ReactComponent as IconWhyUs} from "../../../icons/icon-why-us.svg";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";
import {PageTitle} from "../../../components/page-title/page-title";

const HomePageWhyChoosingUsComponent = () => {
  return (
    <section id="home-page-why-choosing-us" className="gray-light-bg">
      <div className="container py-5">
        <div className="row m-0">
          <div className="col-lg-6 order-1 order-lg-0">
            <IconWhyUs className="w-100" />
          </div>
          <div className="col-lg-6">
            <div className="section-heading mb-2 d-flex flex-column">
              <PageTitle title="Tại sao Khách hàng lại chọn chúng tôi" />
            </div>
            <p className="text-muted mb-0">
              Với kinh nghiệm 10 năm trong lĩnh vưc phát triến số hóa.
            </p>
            <ul className="list-style-disc ps-4">
              {[
                "Đội ngũ phát triển nhiệt huyết, cùng kỹ năng chuyên sâu",
                "Luôn đổi mới công nghệ nhằm đáp ứng nhu cầu ngày càng cao của doanh nghiệp",
                "Gia tăng giá trị cho khách hàng là phương châm hành động của chúng tôi",
                "Luôn đồng hành và xây dựng giải pháp phù hợp với đặc thù từng doanh nghiệp",
              ].map((value, index) => {
                return (
                  <li key={index}>
                    {value}
                  </li>
                );
              })}
            </ul>
            <div className="py-4">
              <BookADemoButton />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageWhyChoosingUsComponent;
