import { useLocation } from "react-router-dom";
import { BookADemoButton } from "../book-a-demo/book-a-demo";
import { productsJson } from "../../contents";

const NavBarComponent = () => {
  const location = useLocation();

  if (location.pathname.includes("/giai-phap-van-tai-tms")) {
    window.scrollTo(0, 0);
    return <_TmsSolutionPageNavbar />;
  }

  if (location.pathname.includes("/giai-phap-phan-phoi-dms")) {
    window.scrollTo(0, 0);
    return <_DmsSolutionPageNavbar />;
  }

  if (location.pathname.includes("/giai-phap-zalo-apps")) {
    window.scrollTo(0, 0);
    return <_ZaloSolutionPageNavbar />;
  }

  if (location.pathname.includes("/giai-phap-ecommerce")) {
    window.scrollTo(0, 0);
    return <_EcommerceSolutionPageNavbar />;
  }

  if (
    location.pathname.includes("/tin-tuc") ||
    location.pathname.includes("/chinh-sach-bao-mat")
  ) {
    window.scrollTo(0, 0);
    return <_CommonPageNavbar />;
  }

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo.png"
              width={120}
              alt="PyCOM"
              className="img-fluid logo"
            />
          </a>
          <div className="d-flex justify-content-center align-items-center flex-row-reverse">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedHomeContent"
              aria-controls="navbarSupportedHomeContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu" />
            </button>
            <BookADemoButton className="d-lg-none m-0 me-2" />
          </div>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedHomeContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <_ProductsDropdown defaultLabelText="Sản phẩm" />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#giai-phap"
                  href="#giai-phap"
                >
                  Giải pháp
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#gioi-thieu"
                  href="#gioi-thieu"
                >
                  Giới thiệu
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#home-page-screenshots"
                  href="#home-page-screenshots"
                >
                  Screenshots
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#khach-hang"
                  href="#khach-hang"
                >
                  Đối tác
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#tin-tuc"
                  href="#tin-tuc"
                >
                  Tin tức
                </a>
              </li>
              {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link page-scroll dropdown-toggle"
                    href="/blog/"
                    id="navbarBlogPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Tin tức &amp; Blog
                    <span className="custom-nav-badge badge text-bg-danger badge-pill">
                      New
                    </span>
                  </a>
                </li> */}
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#lien-he">
                  Liên hệ
                </a>
              </li>
              <li className="nav-item">
                <BookADemoButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBarComponent;

const _ProductsDropdown = ({
  defaultLabelText,
}: {
  defaultLabelText: string;
}) => {
  const products = productsJson;

  return (
    <>
      <a
        className="nav-link page-scroll dropdown-toggle"
        href="#san-pham"
        id="navbarDropdownPage"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {defaultLabelText}
      </a>
      <div
        className="dropdown-menu submenu"
        aria-labelledby="navbarDropdownPage"
      >
        {products.map((item, index) => (
          <a
            key={index}
            className="dropdown-item"
            href={item.href}
            target={item.target}
          >
            {item.title}
          </a>
        ))}
      </div>
    </>
  );
};

const _CommonPageNavbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo.png"
              width={120}
              alt="logo"
              className="img-fluid logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedTmsSolutionContent"
            aria-controls="navbarSupportedTmsSolutionContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu" />
          </button>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedTmsSolutionContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <_ProductsDropdown defaultLabelText="Sản phẩm" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const _TmsSolutionPageNavbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo-tms.png"
              width={120}
              alt="PyCOM TMS"
              className="img-fluid logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedTmsSolutionContent"
            aria-controls="navbarSupportedTmsSolutionContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu" />
          </button>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedTmsSolutionContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <_ProductsDropdown defaultLabelText="TMS" />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#van-de"
                  href="#van-de"
                >
                  Vấn đề
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#giai-phap"
                  href="#giai-phap"
                >
                  Giải pháp
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#tinh-nang"
                  href="#tinh-nang"
                >
                  Tính năng
                </a>
              </li>
              <li className="nav-item">
                <BookADemoButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const _DmsSolutionPageNavbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo-dms.png"
              width={120}
              alt="PyCOM DMS"
              className="img-fluid logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedDmsSolutionContent"
            aria-controls="navbarSupportedDmsSolutionContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu" />
          </button>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedDmsSolutionContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <_ProductsDropdown defaultLabelText="DMS" />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#van-de"
                  href="#van-de"
                >
                  Vấn đề
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#giai-phap"
                  href="#giai-phap"
                >
                  Giải pháp
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#quy-trinh"
                  href="#quy-trinh"
                >
                  Quy trình
                </a>
              </li>
              <li className="nav-item">
                <BookADemoButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const _ZaloSolutionPageNavbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo-zalo-mini-app.png"
              width={120}
              alt="PyCOM ZALO Mini App"
              className="img-fluid logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedDmsSolutionContent"
            aria-controls="navbarSupportedDmsSolutionContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu" />
          </button>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedDmsSolutionContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <_ProductsDropdown defaultLabelText="Zalo Mini Apps service" />
              </li>
              <li className="nav-item">
                <BookADemoButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const _EcommerceSolutionPageNavbar = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/img/logo-ecommerce.png"
              width={120}
              alt="PyCOM E-commerce"
              className="img-fluid logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedDmsSolutionContent"
            aria-controls="navbarSupportedDmsSolutionContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="ti-menu" />
          </button>
          <div
            className="collapse navbar-collapse main-menu justify-content-end"
            id="navbarSupportedDmsSolutionContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <_ProductsDropdown defaultLabelText="E-commerce" />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#tinh-nang-tong-quan"
                  href="#tinh-nang-tong-quan"
                >
                  Tổng quan
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll"
                  target="#tinh-nang-chi-tiet"
                  href="#tinh-nang-chi-tiet"
                >
                  Chi tiết
                </a>
              </li>
              <li className="nav-item">
                <BookADemoButton />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
