import Snackbar from "awesome-snackbar";
import axios from "axios";
import { FormEvent, useRef, useState } from "react";
import { PageTitle } from "../page-title/page-title";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUsComponent = () => {
  const [isRegistering, setRegisterState] = useState(false);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [capVal, setCapVal] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const emailRegex: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const phoneNumberRegex: RegExp = /^(?:\+?84|0)(?:\d{9}|\d{10})$/;

  const _handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!capVal) {
      new Snackbar("Vui lòng xác nhận không phải robot", {
        position: "bottom-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    _onRegistered({
      onSuccess: () => {
        // Reset the reCAPTCHA widget
        if (recaptchaRef.current) {
          recaptchaRef.current!.reset();
        }

        setCapVal(null);
      },
    });
  };

  const _onRegistered = async ({ onSuccess }: { onSuccess: () => void }) => {
    if (!fullname) {
      new Snackbar("Vui lòng nhập họ tên", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    if (email && !emailRegex.test(email)) {
      new Snackbar("Địa chỉ email không hợp lệ", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    if (!phone) {
      new Snackbar("Vui lòng nhập số điện thoại", {
        position: "bottom-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    } else if (!phoneNumberRegex.test(phone)) {
      new Snackbar("Số điện thoại không hợp lệ", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
      return;
    }

    setRegisterState(true);

    try {
      const response = await axios.post(
        "https://delivery-system-api.pycom.vn/api/pycom-consultation-registration",
        {
          name: fullname,
          email: email,
          phoneNumber: phone,
          companyName: companyName,
          applicationName: "Liên hệ tư vấn PyCOM Solutions - Website",
        },
        {
          headers: {
            accept: "application/json",
            "access-code": "d5ee4822-1054-4c34-8bd5-bb8ba21d3629",
          },
        }
      );

      if (response.status === 200) {
        onSuccess();

        new Snackbar(
          `Cám ơn bạn đã đăng ký trải nghiệm dịch vụ của PyCOM.\nChúng tôi sẽ liên
          lạc với bạn trong thời gian sớm nhất`,
          {
            position: "bottom-center",
            timeout: 5000,
            style: {
              container: [["background-color", "#21D375"]],
            },
          }
        );

        setFullname("");
        setEmail("");
        setPhone("");
        setCompanyName("");
        setRegisterState(false);

        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: "gtm-dataLayer-contact-submit",
          });
        }
        return;
      }

      new Snackbar(`Gửi yêu cầu bị lỗi - HTTP Status Code ${response.status}`, {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
    } catch (error) {
      console.error(error);

      new Snackbar(error?.toString() ?? "Gửi yêu cầu bị lỗi", {
        position: "top-center",
        style: {
          container: [["background-color", "red"]],
        },
      });
    }
  };

  return (
    <section id="lien-he" className="contact-us py-5">
      <div className="container text-center">
        <PageTitle title="Liên hệ với chúng tôi" className="mb-0" />
        <p className="my-3">
          Bạn có bất cứ câu hỏi hoặc yêu cầu gì xin hãy liên hệ với chúng tôi
        </p>
      </div>
      <div
        className="container contact-form-container"
        id="contactFormContainer"
      >
        <div className="row">
          <div className="col-12 pb-3 message-box d-none">
            <div className="alert alert-danger" />
          </div>
          <div className="col-md-5">
            <div className="footer-address">
              <h6>
                <strong>Địa chỉ văn phòng:</strong>
              </h6>
              <p>
                Villa D6, Village An Phú Đông, An Phú Đông, Quận 12, Hồ Chí Minh
              </p>
              <ul>
                <li>
                  <span>
                    <b>Phone: </b>
                    <a href="tel:+84909293228"> +84 909 293 228</a> (Mr. Hoàng)
                  </span>
                </li>
                <li>
                  <span>
                    <b>Email: </b>
                    <a href="mailto:info@pycom.vn">info@pycom.vn</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <form className="col-md-7" onSubmit={_handleSubmit}>
            <h5>Gửi yêu cầu cho PyCOM</h5>
            <div className="row">
              <div className="col-sm-6 col-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  placeholder="Nhập họ và tên"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Nhập email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <input
                  type="tel"
                  name="phone"
                  className="form-control"
                  id="phone"
                  placeholder="Nhập số điện thoại"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <input
                  type="text"
                  name="company"
                  size={40}
                  className="form-control"
                  id="company"
                  placeholder="Nhập tên công ty"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lf5NPIpAAAAAG9wZYY4kjIJrElD9mTBpo1fHUE7"
              style={{
                transform: "scale(0.88)",
                WebkitTransform: "scale(0.88)",
                transformOrigin: "0 0",
                WebkitTransformOrigin: "0 0",
              }}
              onChange={(value) => setCapVal(value)}
            />

            <div className="col-sm-12 mt-3">
              <button
                className="btn btn-primary btnContactUs"
                id="btnContactUs"
                type="submit"
              >
                {isRegistering ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <span>Gửi</span>
                )}
              </button>
            </div>
            <p className="form-message" />
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUsComponent;
