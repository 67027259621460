import { Splide, SplideSlide } from "@splidejs/react-splide";
import {PageTitle} from "../../../components/page-title/page-title";

const HomePageOurClientsComponent = () => {
  return (
    <section id="khach-hang" className="client-section py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="section-heading text-center mb-5">
              <PageTitle title="Khách hàng của chúng tôi" />
              <p>PyCOM được sự tin tưởng và đồng hành cùng</p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <Splide
            options={{
              type: "loop",
              autoplay: true,
              pauseOnHover: false,
              perPage: 5,
              pagination: false,
              arrows: false,
              speed: 4500,
              easing: "linear",
              gap: "5rem",
              breakpoints: {
                480: {
                  perPage: 2,
                },
                960: {
                  perPage: 3,
                },
              },
            }}
          >
            <SplideSlide>
              <img
                src="./img/logo/client01-logo.png"
                alt="client logo"
                className="img-fluid"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="./img/logo/client02-logo.png"
                alt="client logo"
                className="img-fluid"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="./img/logo/client03-logo.png"
                alt="client logo"
                className="img-fluid"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="./img/logo/client04-logo.png"
                alt="client logo"
                className="img-fluid"
              />
            </SplideSlide>
            <SplideSlide>
              <img
                src="./img/logo/client05-logo.png"
                alt="client logo"
                className="img-fluid"
              />
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </section>
  );
};

export default HomePageOurClientsComponent;
