import { useState } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { ReactComponent as IconCloud } from "../../../icons/icon-cloud.svg";
import { BookADemoButton } from "../../../components/book-a-demo/book-a-demo";

enum _SolutionTabEnum {
  cloud = "Cloud",
  ai = "AI - Big Data",
  testing = "Testing",
  backend = "Backend",
  frontend = "Frontend",
  mobile = "Mobile"
}

const HomePageSolutionsComponent = () => {
  // website viewport
  const [currentTab, setTab] = useState(_SolutionTabEnum.cloud);

  // mobile viewport
  const [collapsedItems, setCollapsedItems] = useState(
    Object.values(_SolutionTabEnum).map((value, index) => ({
      id: index,
      tab: value,
      open: index === 0,
    }))
  );

  const _renderTabBar = () => {
    return (
      <div className="row justify-content-center tabs">
        {Object.values(_SolutionTabEnum).map((value, index) => {
          const cls = index === 0 ? "left" : index === Object.values(_SolutionTabEnum).length - 1 ? "right" : "center";
          return (
            <div
              key={index}
              className={`col-lg-auto mx-0 tab-item ${currentTab === value ? "active" : ""
                } ${cls}`}
              // col-md-4 col-6 mx-lg-4
              onClick={() => {
                if (currentTab !== value) {
                  setTab(value);
                }
              }}
            >
              {_renderTabBarIcon(value)}
              <span className="ms-0">{value}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const _renderTabContent = () => {
    switch (currentTab) {
      case _SolutionTabEnum.cloud:
        return <_CloudTabContent />;
      case _SolutionTabEnum.ai:
        return <_AiTabContent />;
      case _SolutionTabEnum.testing:
        return <_TestingContent />;
      case _SolutionTabEnum.backend:
        return <_BackendContent />;
      case _SolutionTabEnum.frontend:
        return <_FrontendContent />;
      case _SolutionTabEnum.mobile:
        return <_MobileContent />;
      default:
        return <></>;
    }
  };

  const _renderTabBarIcon = (tab: _SolutionTabEnum) => {
    let srcIcon = "";
    switch (tab) {
      case _SolutionTabEnum.cloud:
        srcIcon = "./img/icons/cloud.png";
        break;
      case _SolutionTabEnum.ai:
        srcIcon = "./img/icons/ai.png";
        break;
      case _SolutionTabEnum.testing:
        srcIcon = "./img/icons/testing.png";
        break;
      case _SolutionTabEnum.backend:
        srcIcon = "./img/icons/back-end.png";
        break;
      case _SolutionTabEnum.frontend:
        srcIcon = "./img/icons/front-end.png";
        break;
      case _SolutionTabEnum.mobile:
        srcIcon = "./img/icons/mobile-app.png";
        break;
      default:
        return <></>;
    }
    // return <i className={`py-2 fa ${srcIcon}`} aria-hidden="true" />;
    return <img src={srcIcon} aria-hidden="true" height="28px" width="28px" />
  };

  const _renderCardContentForMobileView = () => {
    return (
      <div className="d-flex flex-column">
        {collapsedItems.map((item, index) => (
          <div
            key={index}
            className="card p-4 mb-4"
            aria-controls={`solutionTabEnum_${index}`}
            aria-expanded={item.open}
            onClick={() => {
              setCollapsedItems(
                [...collapsedItems].map((e) =>
                  e.id === item.id ? { ...e, open: !item.open } : e
                )
              );
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {_renderTabBarIcon(item.tab)}
                <span className="ms-3 fw-bold">{item.tab}</span>
              </div>
              <i
                className={`fa ${item.open ? "fa-chevron-up" : "fa-chevron-down"
                  } d-flex align-items-center`}
                aria-hidden="true"
              ></i>
            </div>

            <Collapse in={item.open}>
              <div id={`solutionTabEnum_${index}`}>
                {_renderCardBodyForMobileView(item.tab)}
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    );
  };

  const _renderCardBodyForMobileView = (tab: _SolutionTabEnum) => {
    switch (tab) {
      case _SolutionTabEnum.cloud:
        return <_CloudTabContent />;
      case _SolutionTabEnum.ai:
        return <_AiTabContent />;
      case _SolutionTabEnum.testing:
        return <_TestingContent />;
      case _SolutionTabEnum.backend:
        return <_BackendContent />;
      case _SolutionTabEnum.frontend:
        return <_FrontendContent />;
      case _SolutionTabEnum.mobile:
        return <_MobileContent />;
      default:
        return <></>;
    }
  };

  return (
    <section id="giai-phap">
      <div className="container">
        <div className="row justify-content-center mt-0">
          <div className="col-md-8">
            <div className="d-flex flex-column align-items-center">
              <div className="section-heading text-center">
                <h4 className="color-d-grey mb-2 main-title">
                  Giải pháp công nghệ chúng tôi cung cấp
                </h4>
                <p className="sub-title">
                  PyCOM TMS với lợi thế về số hóa toàn bộ quy trình vận tải bằng những công nghệ mới nhất
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Viewport for Web */}
        <div className="d-lg-block d-none">{_renderTabBar()}</div>
        <div className="d-lg-flex d-none row py-3 pb-4">
          {
            [_SolutionTabEnum.cloud,_SolutionTabEnum.testing, _SolutionTabEnum.frontend].includes(currentTab) &&
               <>
              <div className="col-md-5 ms-md-auto d-md-block d-none mt-4">
              <IconCloud className="img-fluid" />
            </div>
            <div className="col-md-7 p-sm-0 p-4 d-flex flex-column justify-content-center">{_renderTabContent()}</div>
              </>
            }

{
            [_SolutionTabEnum.ai,_SolutionTabEnum.backend, _SolutionTabEnum.mobile].includes(currentTab) &&
               <>
               <div className="col-md-1"></div>
            <div className="col-md-6 p-sm-0 p-4 d-flex flex-column justify-content-center">{_renderTabContent()}</div>
            <div className="col-md-5 ms-md-auto d-md-block d-none mt-4">
              <IconCloud className="img-fluid" />
            </div>
              </>
            }
            
          
          </div>

        {/* Viewport for Mobile */}
        <div className="d-lg-none d-block">
          {_renderCardContentForMobileView()}
        </div>
      </div>
    </section>
  );
};

export default HomePageSolutionsComponent;

const _CloudTabContent = () => {
  return (
    <>
      <h4 className="color-d-grey mb-1 mt-4">Xây dựng cơ sở dữ liệu người dùng để phân tích và tối ưu hóa</h4>
      <ul className="list-style-disc ps-4 mt-3 tab-content-list">
        <li>An toàn dữ liệu, bảo mật thông tin</li>
        <li>Giảm thiểu chi phí cho việc đầu tư cơ sở hạ tầng</li>
        <li>Dễ dàng nâng cấp hệ thống tùy theo nhu cầu sử dụng</li>
      </ul>
      <div className="py-4">
        <BookADemoButton />
      </div>
    </>
  );
};

const _AiTabContent = () => {
  return (
    <>
      <h4 className="color-d-grey mb-1 mt-4">Xây dựng cơ sở dữ liệu người dùng để phân tích và tối ưu hóa</h4>
      <ul className="list-style-disc ps-4 mt-3 tab-content-list">
        <li className="mb-3 list-style-none">
          <p className="mb-4 fw-bold">Nền tảng AI</p>
          <ul className="list-style-disc ps-4 tab-content-list">
            <li>Chat GPT</li>
            <li>Image Recognition</li>
            <li>AI Assisted Chatbot Development</li>
            <li>Natural Language Processing</li>
            <li>Machine Learning</li>
          </ul>
        </li>
        <li className="list-style-none">
          <p className="mb-4 fw-bold">Ứng dụng</p>
          <ul className="list-style-disc ps-4 tab-content-list">
            <li>Giảm thiểu lỗi do thao tác con người</li>
            <li>Dịch vụ luôn sẵn sàng 24/7</li>
            <li>Tự động hóa các quy trình</li>
            <li>Ra quyết định nhanh chóng, chính xác</li>
            <li>Gợi ý thông minh</li>
            <li>Thấu hiểu khách hàng người dùng</li>
          </ul>
        </li>
      </ul>
      <div className="py-4">
        <BookADemoButton />
      </div>
    </>
  );
};

const _TestingContent = () => {
  return (
    <>
      <h4 className="color-d-grey mb-1 mt-4">Hệ thống kiểm thử đa dạng và toàn diện</h4>
      <ul className="list-style-disc ps-4 mt-3 tab-content-list">
        <li>Manual Testing</li>
        <li>Functional Testing</li>
        <li>Performance Testing</li>
        <li>Automatic Testing</li>
        <li>Security Testing</li>
        <li>Usability Testing</li>
      </ul>
      <div className="py-4">
        <BookADemoButton />
      </div>
    </>
  );
};

const _BackendContent = () => {
  return (
    <>
      <h4 className="color-d-grey mb-1 mt-4">Luôn cập nhật theo dòng chảy công nghệ</h4>
      <div className="col-md-7 p-sm-0 p-4">
        <div className="row mt-4"><div className="col-md-3 col-4">
          <img src="./img/client-6-color.png" height="10vh" alt="logo" className="img-fluid"/></div>
          <div className="col-md-3 col-4">
            <img src="./img/client-7-color.png" height="10vh" alt="logo" className="img-fluid"/></div>
            <div className="col-md-3 col-4">
              <img src="./img/client-8-color.png" height="10vh" alt="logo" className="img-fluid"/>
                </div><div className="col-md-3 col-4">
                  <img src="./img/client-9-color.png" height="10vh" alt="logo" className="img-fluid"/></div></div></div>
      <div className="py-4">
        <BookADemoButton />
      </div>
    </>
  );
};

const _FrontendContent = () => {
  return (
    <>
      <h4 className="color-d-grey mb-1 mt-4">Luôn cập nhật theo dòng chảy công nghệ</h4>
      <div className="col-md-7 p-sm-0 p-4">
        <div className="row mt-4">
          <div className="col-md-3 col-4">
            <img src="./img/logo/react-logo.png" height="10vh" alt="logo" className="img-fluid"/>
            </div><div className="col-md-3 col-4">
              <img src="./img/logo/angular-logo.png" height="10vh" alt="logo" className="img-fluid"/>
                </div><div className="col-md-3 col-4">
                  <img src="./img/logo/html-css-js-logo.png" height="10vh" alt="logo" className="img-fluid"/></div></div></div>
      <div className="py-4">
        <BookADemoButton />
      </div>
    </>
  );
};

const _MobileContent = () => {
  return (
    <>
      <h4 className="color-d-grey mb-1 mt-4">Luôn cập nhật theo dòng chảy công nghệ</h4>
      <div 	className="col-md-7 p-sm-0 p-4">
        <div className="row mt-4">
          <div className="col-md-3 col-4">
            <img src="./img/client-5-color.png" alt="logo" className="img-fluid"/>
            </div><div className="col-md-3 col-4">
              <img src="./img/client-10-color.png" alt="logo" className="img-fluid"/>
              </div><div className="col-md-3 col-4">
                <img src="./img/logo/kotlin-logo.png" alt="logo" className="img-fluid"/></div>
                <div className="col-md-3 col-4">
                  <img src="./img/logo/swift-logo.png" alt="logo" className="img-fluid"/></div></div></div>
      <div className="py-4">
        <BookADemoButton />
      </div>
    </>
  );
};
