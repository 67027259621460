import {useState} from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import {PageTitle} from "../../../components/page-title/page-title";
import {BookADemoButton} from "../../../components/book-a-demo/book-a-demo";
import Content from "./content.json";

const TmsSolutionPageSolutionsComponent = () => {
  return (
    <section id="giai-phap" className="background-img-3 color-white">
      <div className="container py-5">
        <div className="d-flex flex-column align-items-center mb-4">
          <div className="col-md-8">
            <div className="section-heading text-center">
              <PageTitle title="PyCOM TMS giải quyết vấn đề" className="color-white" />
              <p>
                PyCOM TMS với lợi thế về số hóa toàn bộ quy trình vận tải PyCOM TMS với lợi thế về việc Số hóa toàn bộ quy trình vận tải bằng những nền tảng công nghệ mới nhất những công nghệ mới nhất
              </p>
            </div>
          </div>
          <BookADemoButton />
        </div>
        <div className="container">
          <div className="row mt-5">
            {
              Content.tmsSolution.map((s, i) => {
                return (
                  <div className="col-lg-4 col-md-6 mb-3" key={i}>
                    <div className="card single-promo-card single-promo-hover h-100 solution-item">
                      <div className="card-body d-flex flex-column align-items-center">
                        <img
                          src={s.img}
                          alt={s.title}
                          className="rounded-3"
                          style={{ width: "auto", height: "25vh" }}
                        />
                        <div className="pt-2 pb-3">
                          <h5 className="pb-3 text-center border-bottom border-1">
                            {s.title}
                          </h5>
                          {
                            typeof s.content === "string" ? (
                              <img src={s.content} alt={s.title} className="d-block mx-auto mw-100" />
                            ) : null
                          }
                          {
                            Array.isArray(s.content) ? (
                              <ul>
                                {
                                  s.content.map((c, i) => {
                                    return (
                                      <li key={i} className="d-flex align-items-start mb-4 color-d-grey">
                                        <div className="me-3">
                                          <img
                                            className="icon-img"
                                            src="./img/dms/tick-circle.png"
                                            alt="tick-circle"
                                            width="30rem"
                                          />
                                        </div>
                                        <span>{c}</span>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            ) : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default TmsSolutionPageSolutionsComponent;
