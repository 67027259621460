const HomePageHeaderComponent = () => {
  return (
    <section
      className="hero-section py-5 background-img"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between g-4 py-3">
          <div className="col-md-6 col-lg-6">
            <div className="hero-content-left text-white">
              <span className="text-uppercase h6">PY COM SOLUTIONS</span>
              <h1 className="text-white fw-bold">
                Giải pháp Số hóa cho mọi doanh nghiệp!
              </h1>
              <p className="lead">
                {/* Quickly morph client-centric results through performance based
                applications. Proactively facilitate professional human capital
                for cutting-edge. */}
              </p>
              <a
                className="btn btn-light page-scroll"
                target="#giai-phap"
                href="#giai-phap"
              >
                Khám phá các giải pháp
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="hero-animation-img">
              <img src="img/image-14.png" alt="img" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHeaderComponent;
