const DmsSolutionPageHeaderComponent = () => {
  return (
    <section
      id="dms-solution-page-header"
      className="hero-section py-5 background-img"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between g-4">
          <div className="col-md-6 col-lg-6">
            <div className="hero-content-left text-white mt-5">
              <p className="lead mb-1">Phần mềm quản lý kênh phân phối bán hàng</p>
              <h1 className="text-white fw-bold">DMS by PyCOM</h1>
              <ul className="list-style-disc ps-4">
                <li>Công nghệ tiên tiến, dễ dàng sử dụng, bảo mật, linh hoạt trên
                  mọi thiết bị Hệ thống được customize cho từng yêu cầu của doanh
                  nghiệp</li>
                <li>Quản lý hầu hết các nghiệp vụ của một DMS báo cáo thống kê thời
                  gian thực, giúp doanh nghiệp nhanh chóng đưa ra các quyết định
                  kinh doanh phù hợp</li>
                <li>Có thể tích hợp với các hệ thống khác có sẵn của doanh nghiệp
                  tạo một bảng điều khiển & quản lý hoàn chỉnh cho tất cả các
                  nghiệp vụ phân phối & bán hàng</li>
                <li>Chìa khóa để xây dựng quy trình tự động, hạn chế phụ thuộc vào
                  con người để dễ dàng mở rộng quy mô và tăng trưởng.</li>
              </ul>
              <a
                href="#van-de"
                className="btn btn-light page-scroll my-5"
              >
                Tìm hiểu ngay
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="hero-animation-img">
              <img src="img/image-14.png" alt="img" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DmsSolutionPageHeaderComponent;
